<script setup lang="ts">
import type { BlogItemList } from "@/shared/types";
const { t, locale } = useI18n();
const localePath = useLocalePath();
interface BlogItemListType extends BlogItemList {
  maxHeight?: string;
  titleOnly?: boolean;
  imageWidth?: string;
  wallpaper?: boolean;
  description?: boolean;
}
const props = defineProps<BlogItemListType>();
const mountedLocale = locale.value;
const myTran = computed(
  () => props.translations[mountedLocale] || props.translations.en
);
const { width } = useWindowSize();
const mounted = useMounted();
const mobileSize = computed(
  () => props.wallpaper || (width.value < 500 && mounted.value)
);
</script>

<template>
  <template v-if="mobileSize">
    <BlogItemWallpaper
      :pathTo="localePath(`/blog/${props.idCode}`)"
      :image="props.image || props.previewImage"
      :title="myTran.title"
      :readMoreText="t('c-blog-item-readMore')"
      :tags="props.tags"
      :description="myTran.description"
      :show-description="props.description"
    />
  </template>
  <template v-else>
    <BlogItemFullItem
      :pathTo="localePath(`/blog/${props.idCode}`)"
      :previewImage="props.previewImage"
      :title="myTran.title"
      :description="myTran.description"
      :readMoreText="t('c-blog-item-readMore')"
      :tags="props.tags"
      :imageWidth="props.imageWidth"
    />
  </template>
</template>
