<script setup lang="ts">
import { fetchImage } from "@/api";
const props = defineProps<{
  pathTo: string;
  image: string;
  title: string;
  readMoreText: string;
  tags: string[];
  description?: string;
  showDescription?: boolean;
}>();
const tags = computed(() => props.tags.slice(0, 3));
</script>

<template>
  <div class="walpaper-cont">
    <div class="image-cont">
      <el-image
        class="blog-item-image rd"
        :src="fetchImage(props.image)"
        fit="contain"
        lazy
      >
        <template #placeholder>
          <div class="temp-item">
            <el-skeleton style="width: 100%" animated>
              <template #template>
                <el-skeleton-item
                  variant="image"
                  style="width: 100%; height: 240px"
                />
              </template>
            </el-skeleton>
          </div>
        </template>
        <template #error>
          <div class="temp-item">
            <el-skeleton style="width: 100%" animated>
              <template #template>
                <el-skeleton-item
                  variant="image"
                  style="width: 100%; height: 240px"
                />
              </template>
            </el-skeleton>
          </div>
        </template>
      </el-image>
      <div class="description-cont">
        <h3 class="text-white heading-size-SM">
          {{ props.title }}
        </h3>
        <el-row justify="space-between">
          <el-col :span="-1">
            <nuxt-link :to="props.pathTo" v-if="!showDescription">
              <el-button link class="more-btn" type="primary">
                {{ props.readMoreText }}
              </el-button>
            </nuxt-link>
          </el-col>
          <el-col :span="-1" class="nowrap text-nowrap">
            <span
              class="tag-item par-size-XS"
              v-for="(tag, i) in tags"
              :key="`${tag}${i}`"
            >
              {{ tag }}
            </span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="showDescription" class="mb-10">
      <p class="font-size-4">
        {{ props.description }}
      </p>
      <el-row :gutter="20">
        <el-col :span="-1" class="flex-grow">
          <el-tag
            class="mr-1 mb-1"
            type="info"
            v-for="tag in props.tags"
            :key="tag"
          >
            {{ tag }}
          </el-tag>
        </el-col>
        <el-col :span="-1" class="button-controls">
          <nuxt-link :to="props.pathTo">
            <el-button plain type="primary" round>
              {{ props.readMoreText }}
            </el-button>
          </nuxt-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.walpaper-cont {
  position: relative;

  .image-cont {
    position: relative;
  }

  .description-cont {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 50px 20px 20px;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0a0a0a 90%);
    .text-white {
      color: #ebebeb;
    }
    .more-btn {
      margin: 0px -16px;
    }

    .tag-item {
      color: #737373;
      margin-left: 12px;
    }
  }
}
.blog-item-image {
  min-height: 200px;
  max-height: 300px;
  width: 100%;
}
.temp-item {
  min-height: 200px;
}
</style>
